:root {
  --anmatics-blue: #0084be;
  --anmatics-blue-hover: #095393;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #fff;
  color: #374151;
  padding-top: 80px;
}

.name-title {
  font-size: 2rem;
}

sup {
  top: -0.9em;
  font-size: 0.9rem;
}

.sup-bottom {
  top: -0.6em;
  font-size: 0.7rem;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

header {
  background-color: #1a202c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1000;
}

.at-primary {
  color: var(--anmatics-blue);
}

.navbar-light {
  background-color: #fff !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: var(--anmatics-blue-hover) !important;
}

.nav-link.active {
  color: #63b3ed !important;
  font-weight: bold;
}

.home {
  background: url(./images/home-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background-size: cover;
}

.section {
  padding: 60px 0;
}

/* Sections Header
--------------------------------*/
.section-header h3 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  font-weight: 500;
  position: relative;
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}

@media (max-width: 767px) {
  .section-header p {
    width: 100%;
  }
}

/* Service Cards */
.service-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  margin-bottom: 20px;
}

.service-title {
  color: #2b6cb0;
}

.service-description {
  color: #4a5568;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .service-card {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .service-card {
    padding: 15px;
  }
}

/* About Us Section
--------------------------------*/
#about {
  background: #fff;
  padding: 60px 0;
}

#about .about-container .background {
  margin: 20px 0;
}

#about .about-container .content {
  background: #fff;
}

#about .about-container .title {
  color: #333;
  font-weight: 700;
  font-size: 32px;
}

#about .about-container p {
  line-height: 26px;
}

#about .about-container p:last-child {
  margin-bottom: 0;
}

#about .about-container .icon-box {
  background: #fff;
  background-size: cover;
  padding: 0 0 30px 0;
}

#about .about-container .icon-box .icon {
  float: left;
  background: #fff;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #007bff;
  transition: all 0.3s ease-in-out;
}

#about .about-container .icon-box .icon i {
  color: #007bff;
  font-size: 24px;
}

#about .about-container .icon-box:hover .icon {
  background: #007bff;
}

#about .about-container .icon-box:hover .icon i {
  color: #fff;
}

#about .about-container .icon-box .title {
  margin-left: 80px;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 18px;
}

#about .about-container .icon-box .title a {
  color: #283d50;
}

#about .about-container .icon-box .description {
  margin-left: 80px;
  line-height: 24px;
  font-size: 14px;
}

#about .about-extra {
  padding-top: 60px;
}

#about .about-extra h4 {
  font-weight: 600;
  font-size: 24px;
}

.bg-light {
  background-color: #edf2f7 !important;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Ensures each card takes full height of the container */
  padding: 20px;
  border: 1px solid #e2e8f0;
  /* Optional: Add border for visual separation */
  border-radius: 8px;
  background-color: #ffffff;
  transition: transform 0.3s, background-color 0.3s;
}

.feature:hover {
  transform: translateY(-5px);
  background-color: #f0f0f0;
  /* Change to your desired hover background color */
}

.feature h2 {
  color: #2b6cb0;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.feature p {
  color: #4a5568;
  font-size: 1rem;
}

.contact-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control {
  border-radius: 4px;
  border-color: #d2d6dc;
}

.form-control:focus {
  border-color: var(--anmatics-blue);
  ;
  box-shadow: none;
}

.btn-primary {
  background-color: var(--anmatics-blue);
  ;
  border-color: var(--anmatics-blue);
  ;
  padding: 10px 30px;
  font-size: 16px;
}

.btn-primary:hover {
  background-color: var(--anmatics-blue-hover);
  ;
  border-color: var(--anmatics-blue-hover);
  ;
}

footer {
  background-color: var(--anmatics-blue);
  ;
  color: #e2e8f0;
  padding: 30px 0;
  margin-top: 60px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  background-size: cover;
}

footer p {
  margin-bottom: 0.5rem;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #2b6cb0;
}

.footer-links ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: #ffffff;
}

.social-icons {
  list-style-type: none;
  padding-left: 0;
  /* text-align: center; */
}

.social-icons .social-icon {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  transition: transform 0.3s, color 0.3s;
}

.social-icons .social-icon:hover {
  color: #2b6cb0;
  transform: scale(1.1);
}

.social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.social-icons li a {
  color: #e2e8f0;
  font-size: 18px;
}

.social-icons li a:hover {
  color: #2b6cb0;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .logo img {
    height: 30px;
  }
}

@media (max-width: 576px) {
  .nav-link {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* Contact Form Styles */
#contact {
  background-color: #f8f9fa;
  padding: 80px 0;
}

#contact .card {
  border: none;
  background: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

#contact .card-body {
  padding: 30px;
}

#contact .card-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

#contact .form-group {
  margin-bottom: 20px;
}

#contact .form-control {
  height: 50px;
  border-radius: 0;
  border: 1px solid #ced4da;
  padding: 0 15px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
}

#contact .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#contact textarea.form-control {
  height: auto;
}

#contact .btn-primary {
  background-color: var(--anmatics-blue);
  ;
  border-color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  width: 100%;
}

#contact .btn-primary:hover {
  background-color: var(--anmatics-blue-hover);
  ;
  border-color: #fff;
}

@media (max-width: 768px) {
  #contact .card-body {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  #contact .card-body {
    padding: 15px;
  }
}

.carousel-item {
  transition: transform 0.5s ease-in-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

.animate-slideDown {
  animation: slideDown 0.5s forwards;
}

.animate-fadeIn {
  animation: fadeIn 0.5s forwards;
}

.animate-zoomIn {
  animation: zoomIn 0.5s forwards;
}

.contact-title {
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-title::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 4px;
  background-color: var(--anmatics-blue);
  ;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  animation: moveUnderline 2s infinite;
}

@keyframes moveUnderline {

  0%,
  100% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(-25%);
  }
}

.section-header.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animate-rocket {
  position: relative;
  animation: rocketAnimation 1s ease-in-out infinite alternate,
    colorAnimation 1s ease-in-out infinite alternate;
}

@keyframes rocketAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-4px);
  }
}

@keyframes colorAnimation {
  0% {
    color: #007bff;
    /* Start color */
  }

  50% {
    color: #4caf50;
    /* Mid color */
  }

  100% {
    color: #dc3545;
    /* End color */
  }
}

a.navbar-brand.logo.at-primary.name-title {
  color: var(--anmatics-blue);
  ;
}